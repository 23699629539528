interface EnvVariables {
  businessEmail: string,
  isDev: ComputedRef<boolean>,
  isProd: ComputedRef<boolean>,
  dev: boolean;
  prod: boolean;
  ssr: boolean;
  siteUrl: string;
  prismicAccessToken: string;
  prismicEndpoint: string;
  assChannel: string;
  engChannel: string;
  tlcChannel: string;
  assApiToken: string;
  engApiToken: string;
  tlcApiToken: string;
  trustpilotBusinessId: string;
  trustpilotUrl: string;
  trustpilotTemplateId: string;
}

export const useEnvVariables = (): EnvVariables => {
  const isDev = computed(() => !!import.meta.env.DEV)
  const isProd = computed(() => !!import.meta.env.DEV)

  return {
    businessEmail: import.meta.env.VITE_BUSINESS_EMAIL,
    isDev,
    isProd,
    dev: import.meta.env.DEV,
    prod: import.meta.env.PROD,
    ssr: import.meta.env.SSR,
    siteUrl: import.meta.env.SITE_URL as string || '',
    prismicAccessToken: import.meta.env.VITE_PRISMIC_ACCESS_TOKEN as string || '',
    prismicEndpoint: import.meta.env.VITE_PRISMIC_ENDPOINT as string || '',
    assChannel: import.meta.env.VITE_EUTEL_CHANNEL_ASS as string || '',
    engChannel: import.meta.env.VITE_EUTEL_CHANNEL_ENG as string || '',
    tlcChannel: import.meta.env.VITE_EUTEL_CHANNEL_TLC as string || '',
    assApiToken: import.meta.env.VITE_EUTEL_API_TOKEN_ASS as string || '',
    engApiToken: import.meta.env.VITE_EUTEL_API_TOKEN_ENG as string || '',
    tlcApiToken: import.meta.env.VITE_EUTEL_API_TOKEN_TLC as string || '',
    trustpilotBusinessId: import.meta.env.VITE_TRUSTPILOT_BUSINESSI_UNIT_ID as string || '',
    trustpilotUrl: import.meta.env.VITE_TRUSPILOT_URL as string || '',
    trustpilotTemplateId: import.meta.env.VITE_TRUSTPILOT_TEMPLATE_ID as string || '',
  }
}
