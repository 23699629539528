import { defineStore } from 'pinia'

export const useUIStore = defineStore('ui', () => {
  const loadingCount = ref(0)
  const overlay = ref(false)
  const isNewsletterDialogOpen = ref(false)

  const loading = computed(() => loadingCount.value > 0)

  const setLoading = (value: boolean) => {
    loadingCount.value += value ? 1 : -1
  }

  const setOverlay = (value: boolean) => {
    overlay.value = value
  }

  const openNewsletterDialog = () => {
    isNewsletterDialogOpen.value = true
  }

  const closeNewsletterDialog = () => {
    isNewsletterDialogOpen.value = false
  }

  return {
    loading,
    overlay,
    isNewsletterDialogOpen,
    
    setLoading,
    setOverlay,
    openNewsletterDialog,
    closeNewsletterDialog,
  }
})
